import React from 'react';
import { prices } from './sushiro'

const DishCalculator = ({ quantities, addDish, minusDish }) => {
    return (
        <div class="eachDishCenter">
            {prices.map((price, index) => {
                return (
                    // not use class dish40 anymore
                    <div class="dish40">
                        <button onClick={() => minusDish(index)}>-{price}</button>
                        <span class="dish-label">Quantity: <span id="dish1Quantity">{quantities[index]}</span></span>
                        <button onClick={() => addDish(index)}>+{price}</button>
                    </div>
                );
            })}
        </div>
    )
}

export default DishCalculator;