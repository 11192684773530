import React from 'react';
import { calculateTotalAmountFromEveryone } from './sushiro';

const BillSummary = ({ eachPersonBillSummary, SubTotal, serviceCharge, totalPriceWithServiceCharge, clearCalculator, undoLastAction, CalNextPerson }) => {
    const [showTotalAmountFromEveryone, setShowTotalAmountFromEveryone] = React.useState(false);
    const clearPage = () => {
        clearCalculator();
        setShowTotalAmountFromEveryone(false);
    }
    const handleUndoLastAction = () => {
        if (eachPersonBillSummary.length === 1) {
            setShowTotalAmountFromEveryone(false);  
        }
        undoLastAction();
    }
    const handleCalNextPerson = () => {
        CalNextPerson();
        setShowTotalAmountFromEveryone(true);
    }
    const totalAmountFromEveryone = calculateTotalAmountFromEveryone(eachPersonBillSummary);
    return (
        <>
            <h2 id="totalPrice">Sub Total Price: {SubTotal.toLocaleString()} THB</h2>
            <h2 id="serviceCharge">Service Charge (10%): {serviceCharge.toLocaleString()} THB</h2>
            <h2 id="totalPriceWithServiceCharge">Total Price: {totalPriceWithServiceCharge.toLocaleString()} THB</h2>

            <h2 id="totalPrices"></h2>

            <div class="dish">
                <button onClick={() => clearPage()}>Clear</button>
                <button onClick={() => handleUndoLastAction()}>Undo</button>
                <button onClick={() => handleCalNextPerson()}>CalNextPerson</button>
                {/* กรอกเลขอาหารที่ไม่มีใน button เรา */}
            </div>

            {showTotalAmountFromEveryone && (
                <div id="totalPerson" class="totalPersonClass">
                    <h6 id="totalAmountFromEveryone">
                        Total amount from everyone = {totalAmountFromEveryone} THB
                    </h6>
                    {eachPersonBillSummary.map(({ personName, quantities, onePersonBillSummary }) => {
                        return <div>{`${personName}: ${onePersonBillSummary} THB`}
                        {/* &#9;{quantities.reduce(
                            (accumulator, currentValue) => accumulator + currentValue,
                            0,
                        )} Dishes */}
                        </div>
                    })}

                </div>
            )}
        </>
    )
}
export default BillSummary;