import React from 'react';
import './App.css';
import DishCalculator from './DishCalculator';
import BillSummary from './BillSummary';
import { calculateSubTotalAndServiceChargeAndTotalPriceWithServiceCharge } from './sushiro';

function App() {
  const [quantities, setQuantities] = React.useState([0, 0, 0, 0, 0, 0]);
  const [eachPersonBillSummary, setEachPersonBillSummary] = React.useState([]);
  const { SubTotal, serviceCharge, totalPriceWithServiceCharge } =
    calculateSubTotalAndServiceChargeAndTotalPriceWithServiceCharge(quantities);

  const addDish = (index) => {
    setQuantities(prevQuantities => {
      const newQuantities = [...prevQuantities];
      newQuantities[index]++;
      return newQuantities;
    })
  };

  const minusDish = (index) => {
    setQuantities(prevQuantities => {
      const newQuantities = [...prevQuantities];
      if (newQuantities[index] > 0) {
        newQuantities[index]--;
      }
      return newQuantities;
    });
  };

  const clearCalculator = () => {
    setQuantities([0, 0, 0, 0, 0, 0]);
    setEachPersonBillSummary([]);
  }
  const undoLastAction = () => {
    const lastPersonBillSummary = eachPersonBillSummary[eachPersonBillSummary.length - 1];
    setEachPersonBillSummary(prev => {
      return prev.slice(0, prev.length - 1)
    })
    setQuantities(lastPersonBillSummary.quantities);
  }
  const CalNextPerson = () => {
    setEachPersonBillSummary(prev => {
      const personNumber = prev.length + 1;
      return [...prev, 
        {
          personName: `Person ${personNumber}`,
          quantities: quantities,
          onePersonBillSummary: totalPriceWithServiceCharge,
        },
      ]
    })
    setQuantities([0, 0, 0, 0, 0, 0]);
  }
  return (
    <div className="App">
      <header className="App-header">
        <img class="logo"
          src="https://cdn.glitch.global/87b890ca-6106-49ca-aa8a-61c4d3b89a53/SUSHIRO_logo_alphabet.png?v=1693466009771"
          alt="Logo" />

        <h1>Calculator</h1>
        <DishCalculator quantities={quantities} addDish={addDish} minusDish={minusDish} />
        <BillSummary
          SubTotal={SubTotal}
          serviceCharge={serviceCharge}
          totalPriceWithServiceCharge={totalPriceWithServiceCharge}
          eachPersonBillSummary={eachPersonBillSummary}
          clearCalculator={clearCalculator}
          undoLastAction={undoLastAction}
          CalNextPerson={CalNextPerson}
        />
      </header>
    </div>
  );
}

export default App;
